import { MeQuery } from "@mc/common/gql/types";

const STORAGE_KEY = "mc-user";

export default function userExists(): boolean {
  const item = window.localStorage.getItem(STORAGE_KEY);
  if (item) {
    const user = JSON.parse(item);
    return !!(user && user.token);
  }
  return false;
}

export function userToStorage(client: Partial<MeQuery["me"]>, token?: string) {
  window.localStorage.setItem(
    STORAGE_KEY,
    JSON.stringify({
      client,
      token,
    })
  );
}

export function clearUserStorage() {
  window.localStorage.removeItem(STORAGE_KEY);
}

export function getStorageUser(): MeQuery["me"] | null {
  const item = window.localStorage.getItem(STORAGE_KEY);
  if (item) {
    return JSON.parse(item).client;
  }
  return null;
}

export function getStorageToken(): string | undefined {
  const item = window.localStorage.getItem(STORAGE_KEY);
  if (item) {
    return JSON.parse(item).token;
  }
}
