import { CaptureConsole } from "@sentry/integrations";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

export default function initSentry(): void {
  process.env.REACT_APP_SENTRY_ENABLED === "true" &&
    Sentry.init({
      dsn: process.env.REACT_APP_SENTRY_DSN,
      integrations: [new Integrations.BrowserTracing(), new CaptureConsole({ levels: ["error"] })],
      tracesSampleRate: 1.0,
      environment: process.env.REACT_APP_SENTRY_ENVIRONMENT,
    });
}
