import { createRoot } from "react-dom/client";
import App from "./app";
import initSentry from "lib/sentry/init-sentry";
import "@mc/components/core/fonts/fonts.css";

initSentry();

const container = document.getElementById("root");
const root = createRoot(container!);
root.render(<App />);
