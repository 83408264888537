import { TooltipStyled } from "./tooltip.style";
import { TOOLTIP_ID } from "./tooltip.constants";

// This component is a global placeholder.
// The tooltip's position and inner text will be set via
// javascript by each tooltip-wrapper instance

const Tooltip = () => {
  return <TooltipStyled id={TOOLTIP_ID} role="tooltip"></TooltipStyled>;
};

export default Tooltip;
