import { ReactNode } from "react";
import useTracking from "lib/tracking/use-tracking";
import { Navigate, useLocation } from "react-router-dom";

import { LOGIN } from "routing/routes";
import { useUser } from "user/user.hooks";

type Props = {
  element: ReactNode;
};

const PrivateRoute = ({ element }: Props) => {
  const { user } = useUser();
  const location = useLocation();
  // dispatch tracking event when page loaded
  const { Track } = useTracking(
    { event: "page_load", url: location.pathname, clientId: user?._id },
    { dispatchOnMount: true }
  );

  return <Track>{user ? element : <Navigate to={LOGIN} state={{ from: location }} />}</Track>;
};

export default PrivateRoute;
