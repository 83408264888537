import { useRef, useEffect, useCallback } from "react";
import { NotificationClose, NotificationStyled } from "./notification.style";
import { NotificationMode, NotificationPosition, useNotification } from "./notification.context";

const ID = "notification";

const Notification = () => {
  const { text, displayNotification, hideable, hideNotification, mode, position } = useNotification();

  const snackbarElement = useRef<HTMLDivElement | null>();

  const handleResize = useCallback(() => {
    if (snackbarElement && snackbarElement.current) {
      snackbarElement.current.style.top =
        position === NotificationPosition.Top ? `-${8}px` : `${window.innerHeight - 8}px`;
    }
  }, [snackbarElement, position]);

  useEffect(() => {
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
    //eslint-disable-next-line
  }, []);

  return (
    <div role="alert" aria-live="polite">
      <NotificationStyled
        ref={(el) => (snackbarElement.current = el)}
        id={ID}
        warning={mode === NotificationMode.Warning}
        error={mode === NotificationMode.Error}
        position={position}
        hideable={hideable}
        displayNotification={displayNotification}
        style={{
          top: position === NotificationPosition.Top ? `-${8}px` : `${window.innerHeight - 8}px`,
        }}
      >
        {text}
        {hideable && (
          <NotificationClose type="button" iconId="close" onClick={hideNotification} aria-label="Close">
            close
          </NotificationClose>
        )}
      </NotificationStyled>
    </div>
  );
};

export default Notification;
