import styled, { keyframes } from "styled-components/macro";

import { black } from "../core/colours.styles";
import { rem } from "../core/styles";
import Icon from "../icons/Icon";

const rotate = keyframes`
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
`;

const dash = keyframes`
  0% {
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -35px;
  }
  100% {
    stroke-dasharray: 89, 200;
    stroke-dashoffset: -124px;
  }
`;

export const Spinner = styled(Icon)`
  background-color: transparent;
  height: ${rem(24)};
  width: ${rem(24)};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  margin: auto;
  animation: ${rotate} 2s linear infinite;

  circle:first-of-type {
    stroke-width: 4%;
    stroke: rgba(0, 0, 0, 0.2);
    stroke-width: 10%;
    stroke-dasharray: 0;
    stroke-dashoffset: 0;
    stroke-linecap: round;
  }

  circle:last-of-type {
    stroke: ${black};
    stroke-width: 10%;
    stroke-dasharray: 1, 200;
    stroke-dashoffset: 0;
    animation: ${dash} 1.5s ease-in-out infinite;
    stroke-linecap: round;
  }
`;
