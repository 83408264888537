import { gql } from "@apollo/client";

export const OTHER_REQUIREMENTS_FRAGMENT = gql`
  fragment OtherRequirementsFragment on Profile {
    _id
    otherRequirements
    services
    isFamilyHomePurchasedFirst
  }
`;
