import { gql } from "@apollo/client";

export const CORE_LOGIC_PROPERTY_DETAILS_FRAGMENT = gql`
  fragment CoreLogicPropertyDetailsFragment on PropertyDetails {
    id
    coreAttributes {
      id
      beds
      baths
      carSpaces
      landArea
      propertyType
    }
    additionalAttributes {
      id
      yearBuilt
    }
    images {
      id
      defaultImage {
        basePhotoUrl
        largePhotoUrl
        mediumPhotoUrl
      }
      allImages {
        basePhotoUrl
        largePhotoUrl
        mediumPhotoUrl
      }
    }
    location {
      id
      singleLine
      latitude
      longitude
      state
      street {
        nameAndNumber
      }
    }
    lastSales {
      id
      lastSale {
        agencyName
        zoneDescriptionLocal
        saleMethod
        price {
          formatted
          amount
        }
        settlementDate {
          formatted(format: "DD MMM YYYY")
        }
        contractDate {
          formatted(format: "DD MMM YYYY")
          alt: formatted(format: "MMMM YYYY")
          year: formatted(format: "YYYY")
          inputFormat: formatted(format: "YYYY-MM-DD")
        }
      }
    }
    otmSales {
      id
      forSalePropertyCampaign {
        activeCampaign {
          advertisementId
          auctionTime
          auctionDate
          priceDescription
          listingMethod
          daysOnMarket
          price
          agency {
            companyName
            phoneNumber
          }
          agent {
            agent
            phoneNumber
          }
        }
      }
    }
  }
`;

export const DOMAIN_PROPERTY_DETAILS_FRAGMENT = gql`
  fragment DomainPropertyDetailsFragment on DomainProperty {
    id
    address
    bedrooms
    bathrooms
    carSpaces
    areaSize
    photos {
      rank
      date {
        origin
      }
      fullUrl
    }
    propertyType
    history {
      lastSale {
        id
        date {
          origin
          formatted(format: "DD MMM YYYY")
          year: formatted(format: "YYYY")
          inputFormat: formatted(format: "YYYY-MM-DD")
        }
        price {
          amount
          formatted
        }
      }
    }
    addressCoordinate {
      lat
      lon
    }
  }
`;

export const EXISTING_PROPERTIES_FRAGMENT = gql`
  fragment ExistingPropertiesFragment on Profile {
    _id
    personalInformation {
      everOwnedProperty
    }
    existingProperties {
      _id
      dataSource
      address {
        coordinates {
          latitude
          longitude
        }
        country
        fullAddress
        googlePlaceId
        line1
        line2
        postcode
        state
        suburb
      }
      allowManagedByMilkChocolate
      currentValue
      isCurrentValueOverridden
      isSellable
      ownershipPercent
      willingToUseEquity
      equityToUse {
        amount
        formatted
      }
      purchaseAmount
      purchasedAt {
        formatted(format: "YYYY-MM-DD")
      }
      isInvestmentProperty
      excludeFromGamePlan
      expenses {
        amount
        expenseType
        recurrence {
          frequency
        }
      }
      loans {
        currentBalance {
          amount
        }
        currentInterestRate {
          value
          formatted
        }
        originalAmount {
          amount
        }
        originalInterestRate {
          value
          formatted
        }
        originalLoanTermInYears
        lender
        loanType
        nameOfContact
        repaymentType
      }
      income {
        amount
        recurrence {
          frequency
        }
      }
      details {
        ...CoreLogicPropertyDetailsFragment
      }
      domainPropertyDetails {
        ...DomainPropertyDetailsFragment
      }
    }
  }
  ${CORE_LOGIC_PROPERTY_DETAILS_FRAGMENT}
  ${DOMAIN_PROPERTY_DETAILS_FRAGMENT}
`;

export const SEARCH_CORELOGIC_ADDRESS = gql`
  query SearchCoreLogicAddress($text: String!) {
    searchCoreLogicAddress(type: ADDRESS, text: $text) {
      suggestions {
        propertyId
        suggestion
      }
    }
  }
`;

export const GET_CORELOGIC_PROPERTY_DETAILS_FOR_ADDRESS_SEARCH = gql`
  query GetCoreLogicPropertyDetailsForAddressSearch($propertyId: Int!) {
    coreLogicPropertyDetails(propertyId: $propertyId) {
      ...CoreLogicPropertyDetailsFragment
    }
  }
  ${CORE_LOGIC_PROPERTY_DETAILS_FRAGMENT}
`;

export const SEARCH_DOMAIN_ADDRESS = gql`
  query SearchDomainAddress($text: String!) {
    searchDomainAddress(text: $text) {
      id
      address
      relativeScore
    }
  }
`;

export const GET_DOMAIN_PROPERTY = gql`
  query GetDomainProperty($id: String!) {
    getDomainProperty(id: $id) {
      ...DomainPropertyDetailsFragment
    }
  }
  ${DOMAIN_PROPERTY_DETAILS_FRAGMENT}
`;
