import { gql } from "@apollo/client";

export const INVESTMENT_GOALS_FRAGMENT = gql`
  fragment InvestmentGoalsFragment on Profile {
    _id
    finance {
      upfrontInvestment {
        amount
      }
      lumpSumInvestments {
        amount {
          amount
        }
        investedAt {
          formatted(format: "YYYY-MM")
        }
      }
      monthlyExpenseContributions {
        amount
        periodInYear
      }
      isHomeLoanRequired
      hasHomeLoanPreApproved
      loans {
        nameOfContact
        lender
        originalInterestRate {
          value
        }
        originalLoanTermInYears
        originalAmount {
          amount
        }
        loanType
        repaymentType
        expiry {
          formatted(format: "YYYY-MM-DD")
        }
      }
      expectedPassiveIncome
      expectedPassiveIncomeStartsAtYear
    }
    existingProperties {
      equityToUse {
        amount
      }
    }
    minimumFundsRequired {
      amount
      formatted
    }
    totalEquityToUse {
      amount
      formatted
    }
    remainingFundsRequired {
      amount
      formatted
    }
  }
`;
