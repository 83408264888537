import styled, { css, keyframes } from "styled-components/macro";
import { Link } from "react-router-dom";

import { rem } from "../core/styles";
import { black, green, white } from "../core/colours.styles";
import { baseButtonCss, ButtonProps } from "./buttons.styles";

export const basePrimaryButtonCss = css`
  background-color: ${black};
  border: none;
  color: ${white};
`;

export const comeIn = keyframes`
  0% {
    transform: translateY(40px);
  }
  100% {
    transform: translateY(0);
  }
`;

export const ButtonPrimary = styled.button<ButtonProps & { $isSuccess?: boolean }>`
  ${baseButtonCss}
  ${basePrimaryButtonCss}

  ${({ $isSuccess }) =>
    $isSuccess &&
    css`
      background-color: ${green} !important;
      color: ${white};
      transition: all 200ms linear;
      svg {
        fill: ${white};
        transform: translateY(40px);
        margin-left: 0 !important;
        animation-name: ${comeIn};
        animation-duration: 600ms;
        animation-delay: 200ms;
        animation-timing-function: cubic-bezier(0.4, 0, 0.6, 1);
        animation-iteration-count: 1;
        animation-fill-mode: forwards;
      }
    `}
`;

export const ButtonPrimaryLink = styled(Link as any)`
  ${baseButtonCss}
  ${basePrimaryButtonCss}

  display: block;
  height: auto;
  line-height: ${rem(50)};
  text-align: center;
  text-decoration: none;
`;
