import { MouseEvent, ButtonHTMLAttributes } from "react";
import Icon, { InlineSvgIds } from "../icons/Icon";
import { ButtonIconStyled } from "./button-icon.styles";

type Props = {
  iconId: InlineSvgIds;
  iconWidth?: number;
  iconHeight?: number;
  noBorder?: boolean;
  onClick?: (e: MouseEvent<HTMLButtonElement>) => void;
} & ButtonHTMLAttributes<HTMLButtonElement>;

const ButtonIcon = ({ onClick, iconId, iconWidth, iconHeight, noBorder, ...buttonProps }: Props) => {
  return (
    <ButtonIconStyled
      type="button"
      noBorder={noBorder}
      onClick={(e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        onClick && onClick(e);
      }}
      {...buttonProps}
    >
      <Icon id={iconId} width={iconWidth} height={iconHeight} />
    </ButtonIconStyled>
  );
};

export default ButtonIcon;
