import styled from "styled-components";
import { lightPink } from "../core/colours.styles";
import { rem } from "../core/styles";
import { ButtonSecondary } from "./button-secondary.styles";
import { ButtonProps } from "./buttons.styles";
import { IoSparkles } from "react-icons/io5";

const StyledButtonAI = styled(ButtonSecondary)`
  display: flex;
  align-items: center;
  background-color: ${lightPink};
  border: none;

  svg {
    margin-right: ${rem(8)};
  }
`;

export const ButtonAI = ({ children, ...props }: ButtonProps) => {
  return (
    <StyledButtonAI {...props}>
      <IoSparkles />
      {children}
    </StyledButtonAI>
  );
};
