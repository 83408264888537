import { ButtonHTMLAttributes, ReactNode, useRef } from "react";
import { Spinner } from "../spinner/spinner.styles";
import { ButtonSecondary } from "./button-secondary.styles";
import { ButtonProps } from "./buttons.styles";

type Props = {
  children: ReactNode;
  isLoading: boolean;
  isDisabled?: boolean;
  size?: ButtonProps["size"];
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const SecondaryLoadingButton = ({
  children,
  isLoading,
  isDisabled,
  onClick,
  type = "submit",
  ...props
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  return (
    <ButtonSecondary
      type={type}
      ref={buttonRef}
      disabled={isLoading || isDisabled}
      $width={isLoading ? buttonRef.current?.offsetWidth : undefined}
      onClick={onClick}
      {...props}
    >
      {isLoading ? <Spinner id="loader" width="24" /> : children}
    </ButtonSecondary>
  );
};
