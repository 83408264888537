import { useQuery } from "@apollo/client";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { isSignedInVar } from "cache/cache";
import { HOME } from "routing/routes";
import { ME } from "./user.graphql";
import { MeQuery } from "@mc/common/gql/types";
import { clearUserStorage } from "./user.storage";

export const useRedirectIfSignedIn = () => {
  const navigate = useNavigate();
  const { user } = useUser();

  useEffect(() => {
    if (user) {
      navigate(HOME, { replace: true });
    }
  }, [navigate, user]);
};

export const useUser = () => {
  const isSignedIn = isSignedInVar();

  const { data } = useQuery<MeQuery>(ME, {
    skip: !isSignedIn,
    onError: () => {
      clearUserStorage();
      window.location.reload();
    },
  });

  const user = data?.me;

  return {
    isSignedIn,
    user,
  };
};
