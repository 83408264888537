import { ApolloClient, ApolloLink, HttpLink } from "@apollo/client";
import { InMemoryCache } from "@apollo/client/cache";
import { setContext } from "@apollo/client/link/context";
import { onError } from "@apollo/client/link/error";
import { clearUserStorage, getStorageToken, getStorageUser, userToStorage } from "user/user.storage";
import { isSignedInVar } from "cache/cache";
import { ME } from "user/user.graphql";
import { MeQuery } from "@mc/common/gql/types";

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = getStorageToken() || "";
  const options: {
    Authorization?: string;
  } = {};
  if (token) {
    options["Authorization"] = `Bearer ${token}`;
  }
  return {
    headers: {
      ...headers,
      ...options,
    },
  };
});

const cache = new InMemoryCache({
  typePolicies: {
    Profile: {
      merge: true,
    },
    Finance: {
      merge: true,
    },
    Money: {
      merge: true,
    },
    Percentage: {
      merge: true,
    },
    DateType: {
      merge: true,
    },
    Query: {
      fields: {
        me: {
          merge: true,
        },
        cartItems: {
          read() {
            return isSignedInVar();
          },
        },
      },
    },
  },
});

export const client = new ApolloClient({
  link: ApolloLink.from([
    onError(({ graphQLErrors, networkError }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, locations, path }) => {
          if (message.includes("Access denied")) {
            clearUserStorage();
            window.location.reload();
          } else {
            console.error(
              `[GraphQL error]: Message: ${message}, Location: ${JSON.stringify(locations)}, Path: ${JSON.stringify(
                path
              )}`
            );
          }
        });
      }

      if (networkError) console.error(`[Network error]: ${networkError}`);
    }),
    authLink,
    new HttpLink({
      uri: process.env.REACT_APP_GRAPHQL_URI,
      credentials: "same-origin",
    }),
  ]),
  cache: cache,
  resolvers: {}, // this tells apollo to look locally for client state variables
});

const u = getStorageUser();
const token = getStorageToken();
isSignedInVar(!!u);

if (token) {
  client
    .query<MeQuery>({
      query: ME,
      fetchPolicy: "network-only",
      context: {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      },
    })
    .then((res) => {
      if (res?.data?.me) {
        userToStorage(res.data.me, token);
      }
    });
}

client.onResetStore(() => {
  return Promise.resolve().then(() => {
    isSignedInVar(false);
  });
});
