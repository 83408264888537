import { useEffect, useState, useCallback } from "react";

import { BaseThemedCssFunction, css, CSSObject } from "styled-components";
import { highlight } from "./colours.styles";
import { SpacingValue } from "./spacing.styles";

const pixelsPerRem = 16;

export const rem = (val: number | string): string => `${parseFloat(val.toString()) / pixelsPerRem}rem`;

export const BREAKPOINT_SM = 576;
export const BREAKPOINT_MD = 768;
export const BREAKPOINT_LG = 992;
export const BREAKPOINT_XL = 1200;

type MediaSizeObject = {
  xs: number;
  sm: number;
  md: number;
  lg: number;
  xl: number;
};
type MediaSize = keyof MediaSizeObject;

export const mediaSizes: MediaSizeObject = {
  xs: 0,
  sm: BREAKPOINT_SM,
  md: BREAKPOINT_MD,
  lg: BREAKPOINT_LG,
  xl: BREAKPOINT_XL,
};

type MediaFunctionObject = {
  xs: BaseThemedCssFunction<CSSObject>;
  sm: BaseThemedCssFunction<CSSObject>;
  md: BaseThemedCssFunction<CSSObject>;
  lg: BaseThemedCssFunction<CSSObject>;
  xl: BaseThemedCssFunction<CSSObject>;
};

export const mediaUp: MediaFunctionObject = (Object.keys(mediaSizes) as MediaSize[]).reduce((acc, label) => {
  (acc as any)[label] = <Props extends CSSObject>(...args: [CSSObject | TemplateStringsArray, any]) => {
    return css<Props>`
      @media (min-width: ${mediaSizes[label]}px) {
        ${css<Props>(...args)};
      }
    `;
  };
  return acc;
}, {} as MediaFunctionObject);

export const clearfix = `
  &::after {
    display: block;
    clear: both;
    content: '';
  }
`;

export const transition = (transition: string) => {
  return css`
    transition: ${transition};

    @media (prefers-reduced-motion: reduce) {
      transition: none;
    }
  `;
};

export const baseBoxShadowFocus = `0 0 0 2px ${highlight}`;

export const baseFocusState = `
  outline: 0;
  box-shadow: ${baseBoxShadowFocus};
`;

export const standardFocusState = css`
  &:focus {
    box-shadow: ${baseBoxShadowFocus};
    outline: 0;
  }

  &:focus:not(:focus-visible) {
    box-shadow: none;
  }

  &:focus-visible {
    box-shadow: ${baseBoxShadowFocus};
  }
`;

export const borderRadius4 = 4;

export const MEDIA_SM = `(min-width: ${BREAKPOINT_SM}px)`;
export const MEDIA_MD = `(min-width: ${BREAKPOINT_MD}px)`;
export const MEDIA_LG = `(min-width: ${BREAKPOINT_LG}px)`;
export const MEDIA_XL = `(min-width: ${BREAKPOINT_XL}px)`;

export type MinMediaQuery = typeof MEDIA_MD;

export const useMediaQuery = (mediaQuery: MinMediaQuery) => {
  const [isMq, setIsMq] = useState<boolean>(window.matchMedia(mediaQuery).matches);

  const onResize = useCallback<(this: MediaQueryList, ev: MediaQueryListEvent) => any>((e) => setIsMq(e.matches), []);

  useEffect(() => {
    const mql = window.matchMedia(mediaQuery);
    mql.addListener(onResize);

    return () => {
      mql.removeListener(onResize);
    };
    //eslint-disable-next-line
  }, []);

  return isMq;
};

export type MarginProps = {
  $marginLeft?: SpacingValue;
  $marginRight?: SpacingValue;
  $marginTop?: SpacingValue;
  $marginBottom?: SpacingValue;
};

// Don't need to pass argument to this function. The "css" function will access the props from parent styled component automatically.
export function marginCss() {
  return css<MarginProps>`
    ${({ $marginLeft }) =>
      $marginLeft
        ? `
    margin-left: ${rem($marginLeft)};
  `
        : ""}
    ${({ $marginRight }) =>
      $marginRight
        ? `
    margin-right: ${rem($marginRight)};
  `
        : ""}
  ${({ $marginTop }) =>
      $marginTop
        ? `
    margin-top: ${rem($marginTop)};
  `
        : ""}
  ${({ $marginBottom }) =>
      $marginBottom
        ? `
    margin-bottom: ${rem($marginBottom)};
  `
        : ""}
  `;
}
