import styled from "styled-components/macro";
import { rem } from "../core/styles";
import { darkGrey, white } from "../core/colours.styles";
import { baseButtonCss } from "./buttons.styles";

export const ButtonSocial = styled.button`
  ${baseButtonCss};
  background-color: ${white};
  border: 1px solid ${darkGrey};
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding: 0 ${rem(24)};
  height: ${rem(50)};
  width: 100%;
  white-space: nowrap;
  img {
    height: ${rem(30)};
    left: ${rem(12)};
    position: absolute;
  }
`;
