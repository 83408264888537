import styled, { css } from "styled-components/macro";
import { rem } from "../core/styles";
import { fontWeightMedium, fontSize14, fontSize16 } from "../core/typography.styles";
import { lightGrey, darkGrey } from "../core/colours.styles";

export type ButtonProps = {
  size?: "medium" | "small";
  $width?: number | string | undefined;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export const baseDisabledButtonCss = css`
  &:disabled,
  &:disabled:hover {
    background-color: ${lightGrey};
    border: none;
    color: ${darkGrey};
    &:hover {
      cursor: not-allowed;
    }
  }
`;

export const baseButtonCss = css<ButtonProps>`
  box-sizing: border-box;
  border-radius: ${rem(4)};
  display: inline-block;
  vertical-align: middle;
  font-weight: ${fontWeightMedium};
  margin: 0;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  ${baseDisabledButtonCss}

  ${({ size }) => {
    switch (size) {
      case "medium":
        return `
        min-height: ${rem(30)};
          font-size: ${fontSize14};
          padding: ${rem(8)} ${rem(16)};
        `;

      case "small":
        return `
        min-height: ${rem(22)};
          font-size: ${fontSize14};
          padding: ${rem(4)} ${rem(16)};
        `;

      default:
        return `
          min-height: ${rem(40)};
          font-size: ${fontSize16};
          padding: ${rem(12)} ${rem(24)};
        `;
    }
  }}

  ${({ $width }) =>
    $width &&
    `
    width: ${typeof $width === "string" ? $width : rem($width)};
  `}
`;

export const ButtonContainerStyled = styled.div`
  display: flex;
  align-items: center;
  margin: ${rem(48)} 0;

  button {
    margin-right: ${rem(16)};
  }
`;
