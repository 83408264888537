import { ButtonHTMLAttributes, useRef, useEffect, ReactNode } from "react";
import { ButtonPrimary } from ".";
import { MdCheck } from "react-icons/md";
import { Spinner } from "../spinner/spinner.styles";
import { ButtonProps } from "./buttons.styles";

type Props = {
  children: ReactNode;
  isLoading: boolean;
  isDisabled?: boolean;
  isSuccess?: boolean;
  onSuccess?: () => void;
  size?: ButtonProps["size"];
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const PrimaryLoadingButton = ({
  children,
  isLoading,
  isDisabled,
  type = "submit",
  onClick,
  isSuccess,
  onSuccess,
  ...props
}: Props) => {
  const buttonRef = useRef<HTMLButtonElement | null>(null);
  useEffect(() => {
    if (isSuccess && onSuccess) {
      setTimeout(() => {
        onSuccess();
      }, 2000);
    }
  }, [isSuccess]);

  return (
    <ButtonPrimary
      type={type}
      ref={buttonRef}
      disabled={isLoading || isDisabled}
      $width={isLoading || isSuccess ? buttonRef.current?.offsetWidth : undefined}
      $isSuccess={isSuccess}
      onClick={onClick}
      {...props}
    >
      {isSuccess ? <MdCheck size={20} /> : isLoading ? <Spinner id="loader" width="24" /> : children}
    </ButtonPrimary>
  );
};
