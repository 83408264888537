import styled from "styled-components/macro";
import { rem, standardFocusState } from "../core/styles";
import { black, lightPink } from "../core/colours.styles";
import { fontSize12, fontSize14 } from "../core/typography.styles";

export const TOOLTIP_HEIGHT = 32;
export const TOOLTIP_MARGIN = 8;

export const TooltipStyled = styled.div`
  visibility: hidden;
  position: absolute;
  color: ${black};
  background-color: ${lightPink};
  border-radius: ${rem(3)};
  box-sizing: border-box;
  padding: ${rem(12)};
  top: -${TOOLTIP_HEIGHT + TOOLTIP_MARGIN}px;
  z-index: 1000000;

  min-height: ${TOOLTIP_HEIGHT}px;
  max-width: ${rem(300)};

  div {
    font-size: ${fontSize12};
    line-height: ${rem(16)};
  }

  p {
    font-size: ${fontSize12};
    line-height: ${rem(16)};
    margin-bottom: 0;
  }
`;

export const TooltipWrapperStyled = styled.span`
  display: inline-block;
  position: relative;
  vertical-align: middle;
`;

export const TooltipInfoStyled = styled.button.attrs({
  type: "button",
})`
  background-color: ${lightPink};
  border-radius: 2px;
  border: none;
  display: inline-block;
  font-size: ${fontSize14};
  font-style: italic;
  padding: 3px;
  margin-left: ${rem(12)};
  vertical-align: text-bottom;

  ${standardFocusState};

  &:hover {
    cursor: pointer;
  }
`;

export const TooltipModalStyled = styled.div`
  background-color: ${lightPink};
  display: flex;
  min-height: 100%;
  padding: 0 ${rem(20)};
`;

export const TooltipModalContentStyled = styled.div`
  max-width: ${rem(500)};
  margin: auto;
  padding: ${rem(72)} 0;
`;

export const TooltipModalCloseStyled = styled.div`
  height: ${rem(40)};
  width: ${rem(40)};
  position: absolute;
  right: ${rem(16)};
  top: ${rem(16)};
`;

export const TooltipModalCloseLinkStyled = styled.div``;
