import { ReactNode, useEffect, useState } from "react";
import { useUser } from "user/user.hooks";
import { useFacebookAuth } from "lib/facebook/facebook-auth-hooks";

type Props = {
  children: ReactNode;
};

const StartUp = ({ children }: Props) => {
  const { user, isSignedIn } = useUser();
  const [ready, setReady] = useState<boolean>(!isSignedIn && !user);

  useFacebookAuth();

  useEffect(() => {
    if (user && isSignedIn) {
      setReady(true);
    }
  }, [user, setReady, isSignedIn]);

  if (!ready) {
    return null;
  }

  return <>{children}</>;
};

export default StartUp;
