import { Component } from "react";

type AnyObject = {
  [key: string]: any;
};

type Props = { children: any };

type State = {
  hasError: boolean;
};

export default class ErrorBoundary extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { hasError: false };
  }

  componentDidCatch = (error: Error, info: AnyObject) => {
    console.error("componentDidCatch", error, info);
    this.setState({ hasError: true });
  };

  render() {
    if (this.state.hasError) {
      return <div style={{ textAlign: "center" }}>Error</div>;
    }
    return this.props.children;
  }
}
