import styled from "styled-components/macro";
import { ButtonHTMLAttributes } from "react";

import { rem, standardFocusState } from "../core/styles";
import { black, lightGrey } from "../core/colours.styles";
import { baseDisabledButtonCss } from "./buttons.styles";

type RoundButtonIcon = {
  noBorder?: boolean;
  isSmall?: boolean;
} & ButtonHTMLAttributes<HTMLButtonElement>;

export const ButtonIconStyled = styled.button<RoundButtonIcon>`
  background: transparent;
  border-radius: 100%;
  border: ${({ noBorder }) => (noBorder ? "none" : `1px solid ${lightGrey}`)};
  width: ${({ isSmall }) => (isSmall ? "auto" : rem(40))};
  height: ${({ isSmall }) => (isSmall ? "auto" : rem(40))};
  line-height: 0;
  margin: 0;
  stroke: none;
  fill: ${black};
  padding: 0;
  text-align: center;

  &:hover {
    border-color: ${lightGrey};
    cursor: pointer;
  }

  ${standardFocusState}
  ${baseDisabledButtonCss}
`;
