import { createGlobalStyle } from "styled-components";
import { fontFamilyBase } from "./typography.styles";

export const GlobalStyles = createGlobalStyle`
  body {
    font-weight: normal;
    font-family: ${fontFamilyBase};
    line-height: 1.5;
    font-feature-settings: "liga", "kern";
    margin: 0;
    padding: 0;
  }

  ul,
  ul li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  h1, h2, h3, h4, h5, h6, p {
    font-weight: normal;
  }

  input, select, textarea, button {
    font-family: ${fontFamilyBase};
  }

  div, nav {
    box-sizing: border-box;
  }

  .visually__hidden {
    border: 0;
    clip: rect(0, 0, 0, 0);
    height: 1px;
    margin: -1px;
    overflow: hidden;
    padding: 0;
    position: absolute;
    width: 1px;
  }

  a {
    color: inherit;
    text-decoration: none;
  }
`;
