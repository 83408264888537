export function trueFalseStringToBoolean(field?: string | null): boolean | null {
  if (field === "" || field === undefined || field === null) return null;
  return field === "true";
}

export function booleanToTrueFalseString(field: boolean | null | undefined): "true" | "false" | null {
  if (field === undefined || field === null) return null;
  return field ? "true" : "false";
}

export function oneZeroStringToBoolean(value: "0" | "1"): boolean {
  switch (value) {
    case "0":
      return false;
    case "1":
      return true;
  }
}

export function booleanToOneZeroString(value?: boolean | null, defaultValue?: "0" | "1"): "0" | "1" | undefined {
  switch (value) {
    case false:
      return "0";
    case true:
      return "1";
    default:
      return defaultValue ?? undefined;
  }
}

export function booleanToYesNoString(value: boolean | undefined | null): string {
  return value ? "Yes" : "No";
}
