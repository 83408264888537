import { BadgeProps } from "@mc/components/badge/badge.styles";

export function getBryceScoreBadge(doubledScore: number): { badgeProps: BadgeProps; label: string } {
  switch (true) {
    case doubledScore <= 0.25:
      return {
        badgeProps: { colour: "grey" },
        label: "Under average",
      };

    case doubledScore > 0.25 && doubledScore <= 0.5:
      return {
        badgeProps: { colour: "scaleYellow" },
        label: "Average",
      };

    case doubledScore > 0.5 && doubledScore <= 0.75:
      return {
        badgeProps: { colour: "scaleLightGreen" },
        label: "Good",
      };

    case doubledScore > 0.75:
      return {
        badgeProps: { colour: "scaleGreen" },
        label: "Excellent",
      };

    default:
      console.warn("Unknown doubledScore detected in getBryceScoreBadge");
      return { badgeProps: {}, label: "Unknown" };
  }
}

export function getMCScoreBadge(doubledScore: number): { badgeProps: BadgeProps; label: string } {
  switch (true) {
    case doubledScore <= 25:
      return {
        badgeProps: { colour: "grey" },
        label: "Under average",
      };

    case doubledScore > 25 && doubledScore <= 50:
      return {
        badgeProps: { colour: "scaleYellow" },
        label: "Average",
      };

    case doubledScore > 50 && doubledScore <= 75:
      return {
        badgeProps: { colour: "scaleLightGreen" },
        label: "Good",
      };

    case doubledScore > 75:
      return {
        badgeProps: { colour: "scaleGreen" },
        label: "Excellent",
      };

    default:
      console.warn("Unknown doubledScore detected in getMCScoreBadge");
      return { badgeProps: {}, label: "Unknown" };
  }
}
