import styled, { css } from "styled-components/macro";
import { Link } from "react-router-dom";
import { black } from "../core/colours.styles";
import { baseButtonCss, ButtonProps } from "./buttons.styles";

export const baseSecondaryButtonCss = css`
  background-color: transparent;
  border: 1px solid ${black};
  color: ${black};
`;

export const ButtonSecondary = styled.button<ButtonProps>`
  ${baseButtonCss}
  ${baseSecondaryButtonCss}
`;

export const ButtonSecondaryLink = styled(Link)<ButtonProps>`
  ${baseButtonCss}
  ${baseSecondaryButtonCss}

  display: inline-block;
  height: auto;
  text-align: center;
  text-decoration: none;
`;
