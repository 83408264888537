import { gql } from "@apollo/client";

export const PERSONAL_DETAILS_CLIENT_FRAGMENT = gql`
  fragment PersonalDetailsClientFragment on Client {
    _id
    address {
      line1
      line2
      suburb
      state
      postcode
      country
    }
    countryCode
    mobile
    isCitizenOrPR
    currentLocation
    returnYear
  }
`;

export const PERSONAL_DETAILS_PROFILE_FRAGMENT = gql`
  fragment PersonalDetailsProfileFragment on Profile {
    _id
    personalInformation {
      birthday
      hasAdditionalPurchaser
      additionalPurchaserRelationship
      additionalPurchaserFullName
      additionalPurchaserMobile
      additionalPurchaserEmail
      includeAdditionalPurchaserForWhatsappNotifications
    }
  }
`;
