import Big from "big.js";

export default function futureValue({
  presentValue,
  rate = 0,
  periods = 0,
}: {
  presentValue: number;
  rate?: number;
  periods?: number;
}): number {
  const bPresentValue = new Big(presentValue);
  const bRate = new Big(rate);
  const bOne = new Big(1);

  const cappedPeriods = Math.max(periods, 0);

  const fv = bOne.plus(bRate).pow(cappedPeriods).times(bPresentValue);

  return fv.round().toNumber();
}
