import { Swot, swot, SwotValue } from "../constants/swot";

export default function getSwotLabel(type: Swot["type"], enumValue: SwotValue["enumValue"]): string {
  return (
    swot
      .find((s) => s.type === type)
      ?.groups?.map((group) => group.values)
      ?.flat()
      ?.find((value) => value.enumValue === enumValue)?.content ?? ""
  );
}
