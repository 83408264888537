import { Options, useTracking as useTrackingOriginal } from "react-tracking";
import { TrackingData } from "./tracking.constants";

export default function useTracking(
  trackingData?: TrackingData | (() => TrackingData),
  options?: Partial<Options<TrackingData>>
) {
  const trackingHook = useTrackingOriginal(
    {
      ...trackingData,
      timestamp: new Date(),
    },
    {
      dispatch: (data) => {
        if (process.env.REACT_APP_ENVIRONMENT === "local") {
          console.info("tracking data", data);
        }

        // push tracking data to dataLayer and GTM
        // if (Object.keys(data).length > 0) {
        //   (window.dataLayer = window.dataLayer || []).push(data);
        // }
      },
      ...options,
    }
  );

  return trackingHook;
}
