import { useEffect } from "react";
import facebookAuth from "./facebook-auth";

export function useFacebookAuth() {
  useEffect(() => {
    const facebookJsScriptTag = document.createElement("script");

    facebookJsScriptTag.src = "https://connect.facebook.net/en_US/sdk.js";

    facebookJsScriptTag.crossOrigin = "anonymous";

    facebookJsScriptTag.addEventListener("load", () => facebookAuth.init());

    document.body.appendChild(facebookJsScriptTag);
  }, []);
}
