import { ReactComponent as IconAI } from "./icon_ai.svg";
import { ReactComponent as IconDelete } from "./icon_delete.svg";
import { ReactComponent as IconChevronDown } from "./icon_chevron_down.svg";
import { ReactComponent as IconArrowRight } from "./icon_arrow_right.svg";
import { ReactComponent as IconArrowLeft } from "./icon_arrow_left.svg";
import { ReactComponent as IconArrowDown } from "./icon_arrow_down.svg";
import { ReactComponent as IconArrowUp } from "./icon_arrow_up.svg";
import { ReactComponent as IconSearch } from "./icon_search.svg";
import { ReactComponent as IconHelp } from "./icon_help.svg";
import { ReactComponent as IconClose } from "./icon_close.svg";
import { ReactComponent as IconTick } from "./icon_tick.svg";
import { ReactComponent as IconMore } from "./icon_more.svg";
import { ReactComponent as IconLoadMore } from "./icon_load_more.svg";
import { ReactComponent as IconPsychology } from "./icon_psychology.svg";
import { ReactComponent as IconMapComparableActive } from "./map/Comparable-active.svg";
import { ReactComponent as IconMapComparableIncluded } from "./map/Comparable-included.svg";
import { ReactComponent as IconMapComparableRest } from "./map/Comparable-rest.svg";
import { ReactComponent as IconMapProperty } from "./map/Property.svg";
import { ReactComponent as IconBed } from "./property/icon_bed.svg";
import { ReactComponent as IconBath } from "./property/icon_bath.svg";
import { ReactComponent as IconCar } from "./property/icon_car.svg";
import { ReactComponent as IconLandsize } from "./property/icon_landsize.svg";
import { ReactComponent as IconLoader } from "./icon_loader.svg";
import { ReactComponent as IconStar } from "./icon_star.svg";
import { ReactComponent as IconBold } from "./richtext/icon_bold.svg";
import { ReactComponent as IconOrderedList } from "./richtext/icon_ordered_list.svg";
import { ReactComponent as IconUnorderedList } from "./richtext/icon_unordered_list.svg";
import { ReactComponent as IconWarning } from "./icon_warning.svg";
import { ReactComponent as IconTimelineActive } from "./timeline/icon-timeline-active.svg";
import { ReactComponent as IconTimelineComplete } from "./timeline/icon-timeline-complete.svg";
import { ReactComponent as IconTimelineDone } from "./timeline/icon-timeline-done.svg";
import { ReactComponent as IconTimelineFail } from "./timeline/icon-timeline-fail.svg";
import { ReactComponent as IconTimelinePending } from "./timeline/icon-timeline-pending.svg";
import { ReactComponent as IconTimelineWarning } from "./timeline/icon-timeline-warning.svg";
import { ReactComponent as IconTimelineWarningColored } from "./timeline/icon-timeline-waning-orange.svg";
import { ReactComponent as IconDrill } from "./property/icon_drill.svg";
import { ReactComponent as IconSell } from "./property/icon_sell.svg";
import { ReactComponent as IconCottage } from "./property/icon_cottage.svg";
import { ReactComponent as IconTimeline } from "./property/icon_timeline.svg";
import { ReactComponent as IconAutoRenew } from "./property/icon_auto_renew.svg";
import { ReactComponent as IconArticle } from "./property/icon_article.svg";
import { ReactComponent as IconViewTimeline } from "./property/icon_view_timeline.svg";
import { ReactComponent as IconHomePin } from "./property/icon_home_pin.svg";
import { ReactComponent as IconBarChart } from "./property/icon_bar_chart.svg";
import { ReactComponent as IconFilter } from "./property/icon_filter.svg";
import { ReactComponent as IconLocationOn } from "./map/Location_on.svg";
import { ReactComponent as IconThumbDown } from "./property/icon_thumb_down.svg";
import { ReactComponent as IconMilkChocolateLogo } from "./milk_chocolate_logo.svg";
import { ReactComponent as IconPurchase } from "./property/icon_purchase.svg";
import { ReactComponent as IconRepairs } from "./icon_repairs.svg";
import { ReactComponent as IconScheduled } from "./icon_scheduled.svg";

export const inlineSvgMapping = {
  ai: IconAI,
  "chevron-down": IconChevronDown,
  close: IconClose,
  delete: IconDelete,
  "arrow-down": IconArrowDown,
  "arrow-left": IconArrowLeft,
  "arrow-right": IconArrowRight,
  "arrow-up": IconArrowUp,
  search: IconSearch,
  tick: IconTick,
  help: IconHelp,
  more: IconMore,
  loadMore: IconLoadMore,
  bed: IconBed,
  bath: IconBath,
  car: IconCar,
  landsize: IconLandsize,
  loader: IconLoader,
  star: IconStar,
  bold: IconBold,
  orderedList: IconOrderedList,
  unorderedList: IconUnorderedList,
  warning: IconWarning,
  "timeline-active": IconTimelineActive,
  "timeline-complete": IconTimelineComplete,
  "timeline-done": IconTimelineDone,
  "timeline-fail": IconTimelineFail,
  "timeline-pending": IconTimelinePending,
  "timeline-warning": IconTimelineWarning,
  "timeline-warning-colored": IconTimelineWarningColored,
  "map-comparable-active": IconMapComparableActive,
  "map-comparable-included": IconMapComparableIncluded,
  "map-comparable-rest": IconMapComparableRest,
  "map-property": IconMapProperty,
  drill: IconDrill,
  sell: IconSell,
  cottage: IconCottage,
  timeline: IconTimeline,
  autoRenew: IconAutoRenew,
  article: IconArticle,
  homePin: IconHomePin,
  barChart: IconBarChart,
  viewTimeline: IconViewTimeline,
  filter: IconFilter,
  locationOn: IconLocationOn,
  thumbDown: IconThumbDown,
  milkChocolateLogo: IconMilkChocolateLogo,
  purchase: IconPurchase,
  psychology: IconPsychology,
  repairs: IconRepairs,
  scheduled: IconScheduled,
};

export type InlineSvgIds = keyof typeof inlineSvgMapping;

// svg files must not have overlapping path id names
type Props = {
  id: InlineSvgIds;
  width?: string | number;
  height?: string | number;
} & JSX.IntrinsicElements["svg"];

const Icon = ({ id, width = 16, height = 16, ...rest }: Props) => {
  const Svg = inlineSvgMapping[id];
  if (!Svg) {
    console.error(`Received invalid id prop "${id}" to inline-svg.js`);
    return null;
  }
  return <Svg width={width} height={height} {...rest} focusable="false" />;
};

export default Icon;
