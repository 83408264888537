import { FacebookApi, FBInstance } from "./facebook-auth.constants";

declare var window: FacebookApi;

class FacebookOAuth {
  init(onInit?: Function) {
    if (!window.FB?.init) {
      console.error("Facebook did not load");
    }

    window?.FB?.init({
      appId: process.env.REACT_APP_FACEBOOK_AUTH_APP_ID,
      cookie: true,
      xfbml: true,
      version: "v10.0",
    });

    this.isSignedIn(onInit);
  }

  getInstance(): FBInstance {
    return window.FB;
  }

  getBasicProfile() {
    this.getInstance()?.api("/me", function (res: any) {
      console.log("Successful login for: " + res);
    });
  }

  isSignedIn(onGet?: Function) {
    this.getInstance()?.getLoginStatus(onGet);
  }

  signIn(onSignin: Function) {
    this.getInstance()?.login(onSignin, { scope: "public_profile,email" });
  }

  signOut() {
    this.getInstance()?.logout();
  }
}

const facebookAuth = new FacebookOAuth();

export default facebookAuth;
