import { gql } from "@apollo/client";

export const DAISY_PURCHASE_COSTS_FRAGMENT = gql`
  fragment DaisyPurchaseCostsFragment on DaisyPurchaseCosts {
    subtotal {
      formatted
      amount
    }
    deposit {
      formatted
    }
    total {
      formatted
      amount
    }
    firstHomeBuyersGrant {
      formatted
    }
    foreignBuyersDuty {
      formatted
    }
    stampDuty {
      formatted
    }
    bankFees {
      formatted
    }
    legal {
      formatted
    }
    adjustmentsAtSettlement {
      formatted
    }
    insurance {
      formatted
    }
    buildingAndPest {
      formatted
    }
    strataReport {
      formatted
    }
    invasivePlumbingAssessment {
      formatted
    }
    electricalSafetyAssessment {
      formatted
    }
    structuralEngineeringReport {
      formatted
    }
    termiteManagementSystem {
      formatted
    }
    lettingFee {
      formatted
    }
    crystalClean {
      formatted
    }
    maintenanceCost {
      formatted
    }
    milkChocolateMaintenanceFee {
      formatted
    }
    milkChocolateFeeTotal {
      formatted
    }
    qldRegistrationFee {
      formatted
    }
    taxDepreciationSchedule {
      formatted
    }
    loan {
      amount
      formatted
    }
  }
`;
