export const white = "#FFFFFF";
export const black = "#262223";
export const pureBlack = "RGBA(0, 0, 0, 0.8)";
export const pureBlackSolid = "#000000";

export const softGrey = "RGBA(0, 0, 0, 0.05)";
export const softGreySolid = "#F2F2F2";
export const lightGrey = "RGBA(0, 0, 0, 0.15)";
export const lightGreySolid = "#D9D9D9";
export const darkGrey = "RGBA(0, 0, 0, 0.5)";
export const darkGreySolid = "#808080";

export const lightPink = "#F8D2DB";
export const tan = "#F7F0E6";
export const red = "#CE333F";
export const green = "#557F33";
export const highlight = "#FF8E2A";
export const purple = "#652458";
export const yellowish = "#EFD00C";
export const brightGreen = "#A6E760";
export const blue = "#D0E8FF";

export const scaleGreen = "#557F33";
export const scaleLightGreen = "#A8BD5E";
export const scaleYellow = "#EDC500";
export const scaleOrange = "#FF8E2A";
export const scaleRed = "#CE333F";

export const utilityPositive = "#557F33";
export const utilityNegative = "#CE333F";

export const workStroke = "#FFAF6A";
export const workFill = "#FFDEC0";
export const schoolStroke = "#FF89E1";
export const schoolFill = "#FED5F4";
export const socialStroke = "#8AC2FF";
export const socialFill = "#D5EAFF";

export const uploadWidgetWhite = "#F2F2F2";
export const uploadWidgetGrey = "#7F7F7F";
